import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect:"aws_accounts", //设置重定向，默认打开账号管理
    children:[
      {
        path:"aws_accounts",
        name:"aws_accounts",
        meta:{
          isShow:true,
          title:"AWS客户管理"
        },
        component: () => import(/* webpackChunkName: "aws_accounts" */ '../views/EndUserView.vue')
  
      },
      {
        path:"credit_mgmt",
        name:"credit_mgmt",
        meta:{
          isShow:true,
          title:"AWS客户额度调整记录"
        },
        component: () => import(/* webpackChunkName: "credit_mgmt" */ '../views/CreditMgmt.vue')
  
      }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
//路由守卫
router.beforeEach((to,from,next)=>{
  const token:string | null = localStorage.getItem('token');
  if(!token && to.path!=='/login'){
    next('/login')
  } else {
    next()
  }
})
export default router
